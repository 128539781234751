

















































































































































































.tablerounededCorner {
  border: 1px solid #ccc;
  border-radius: 10px;
}
.roundedTable thead tr:last-child th{
  border-bottom: 1px solid #ccc;
}
.roundedTable {
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 0;
}

.roundedTable th{
  padding: .6em;
}

.roundedTable td {
  padding: .4em;
}

.roundedTable th {
  text-align: left;
}

.roundedTable tr:last-child td {
  border-bottom: none;
}
.roundedTable.table-striped tbody tr:nth-of-type(odd) {
  background-color: #eceff6;
}

